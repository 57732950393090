.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #27273099;
  z-index: 100;
}

.modal-container {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 110;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.modal-body {
  background-color: var(--main-background);
  border: 1px solid var(--main-border);
  padding: 1rem;
  margin: 1rem;
  min-width: 50vmin;
  position: relative;
  max-width: 50rem;
  overflow-y: auto;

  @include screenSize(mdMobile) {
    min-width: calc(100vw - 2rem);
    max-width: calc(100vw - 2rem);
    max-height: calc(100 * var(--vh) - 2rem);
  }

  .close-modal-button {
    font-size: 1.5rem;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }

  .modal-body-content {
    margin-top: 1rem;

    .score-container {
      display: flex;
      justify-content: space-evenly;
      align-items: center;
      margin: 2rem 0;

      h3 {
        margin-bottom: 1rem;
      }
    }

    p {
      text-align: left;
    }
  }
}
