header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100vw;
  // text-align: center;
  padding: 1rem 1.5rem;

  .title-container {
    opacity: 1;
    transition: opacity 0.5s ease;

    &.semi-hidden {
      opacity: 0.25;
    }

    h1 {
      margin-bottom: 0;
    }
  }
}
