// Toggle select
.toggle-switch {
  cursor: pointer;
  width: 100%;
  position: relative;

  input {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    margin: 0;
    z-index: 1;
    border: none;
    padding: 0;

    &:checked {
      + label,
      + input + label {
        .outer {
          background-color: var(--secondary-border);
        }

        .inner {
          left: calc(100% - (1rem + 0.2rem));
        }
      }
    }
  }

  label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .outer {
    position: relative;
    display: inline-block;
    width: 2.5rem;
    height: calc(1rem + 0.4rem);
    background-color: var(--main-background);
    transition: background-color 0.2s;
    border: 1px solid var(--main-typography);
    border-bottom-width: 6px;
    box-sizing: content-box;
    margin-bottom: -5px;
  }

  .inner {
    position: absolute;
    left: 0.2rem;
    top: 0.2rem;
    width: 1rem;
    height: 1rem;
    background-color: var(--main-typography);
    // border-radius: 50%;
    transition: left 0.2s;
  }

  &.left {
    .outer {
      left: 0;
      right: auto;
    }
  }
  &.grey-out {
    opacity: 0.3;
  }

  &.hide {
    display: none;
  }
}
