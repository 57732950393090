$square-width: calc((100vmin - 2rem) / 8);
$counter-width: calc(0.2 * $square-width);

.board-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .board {
    border: 1px solid var(--main-border);
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(8, 1fr);
    width: fit-content;
    position: relative;

    &.active {
      border: 1px solid var(--main-typography);

      .board-square {
        border-color: var(--main-typography);
      }
    }

    .board-square {
      width: $square-width;
      height: $square-width;
      border: 1px solid var(--main-border);
      cursor: pointer;
      position: relative;
      z-index: 1;
      transition: background-color 0.3s ease;

      &.active {
        background-color: var(--secondary-border);
      }

      &.disabled {
        cursor: not-allowed;
        color: var(--main-border);
        pointer-events: none;

        h3 {
          color: var(--main-border);
        }

        .counter {
          background-color: var(--main-border);
        }
      }

      .counter {
        width: $counter-width;
        height: $counter-width;
        background-color: var(--main-typography);
        border-radius: 50%;
        margin: 0.1rem;
        position: absolute;
        top: calc(50% - 10%);
        left: calc(50% - 10%);
      }
    }

    .anim-hand-widget {
      position: absolute;
      left: 0;
      right: 0;
      width: 2rem;
      height: 2rem;
      z-index: 20;
      pointer-events: none;
      transition: left 0 ease, top 0 ease;
      overflow: visible;

      .hand-counter {
        width: $counter-width;
        height: $counter-width;
        background-color: var(--main-typography);
        border-radius: 50%;
        margin: 0.1rem;
        position: absolute;
        left: 0;
        top: 0;
        box-shadow: 0 0 1rem #272730;
      }

      &.with-transition {
        transition: left 0.2s ease, top 0.2s ease;
      }

      h4 {
        color: var(--main-background);
        font-size: 1rem;
      }
    }
  }
}

.button-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-height: 50px;
}
